import axios from 'axios'

import { IWeather, IWea } from '@model/weatherType'
import { WeaParams } from '@/utils/constant'

// 路由前缀
const PREFIX = '/weather/api'

/**
 * @: 获取当日天气及未来六天天气预报
 * @param {*}
 * @return {*}
 */
// export async function getWeather() {
//   const data = await axios.get<IWeather>(PREFIX, {
//     params: WeaParams
//   })
//   return data.data
// }
export async function getWeather() {
  const data = await axios.get<IWeather>(PREFIX, {
    params: WeaParams
  })
  return data.data
}

/**
 * @: 获取天气历史数据
 * @param {*}
 * @return {*}
 */
export async function getWea () {
  const data = await axios.get<IWea>('auto/api/select_weather')
  return data.data
}

// export async function getWea () {
//   const data = await axios.get<IWea>('https://www.duomiai.cn/api/select_meteorological_mb3')
//   return data.data
// }
