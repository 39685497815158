// import Switch from 'antd'
import { BorderBox10 } from '@jiaminghi/data-view-react'
import { useCallback, useEffect, useState } from 'react'
import Switchbutton from '../SwitchButton'
import '@/assets/styles/icon.scss'
import style from './style.module.scss'
import axios from 'axios'
import { getToken } from '../../../../src/services/camera'
import { useHistory } from 'react-router-dom'
import { getVoltage } from '@/services/voltage'
import { message } from 'antd'

// function getAccessToken() {
// 调用接口获取最新的accessToken
//   var accessToken = getToken()
//   return accessToken
// }
export default function Monitor() {
  // const [Token, setToken] = useState<string>('')
  // async function getTokenData () {
  //   const data = await getToken()
  //   if (data.data.code === '200') {
  //     setToken(data.data.accessToken)
  //   } else {
  //     message.error('获取accessToken失败！')
  //   }
  // }
  const [Token, setToken] = useState<string>('')
  useEffect(() => {
    async function getTokenData() {
      const data = await getToken()
      if (data.data.code === '200') {
        setToken(data.data.accessToken)
      } else {
        message.error('获取accessToken失败！')
      }
    }
    getTokenData()
  }, [])
  // var accessToken = getAccessToken()
  const history = useHistory()
  // 摄像机云台
  // const ptzControl = useCallback((direction: number) => {
  //   const url = 'https://open.ys7.com/api/lapp/device/ptz/start?deviceSerial=K06025120&channelNo=1&speed=1&acessToken=' + Token + '&direction=' + direction
  //   axios({
  //     method: 'POST',
  //     url: url
  //   })
  // }, [])
  const ptzControl = useCallback((direction: number) => {
    const url = `https://open.ys7.com/api/lapp/device/ptz/start?deviceSerial=K06025120&channelNo=1&speed=1&accessToken=${Token}&direction=${direction}`
    axios.post(url)
  }, [Token])
  const turnLeft = useCallback(() => {
    ptzControl(2)
  }, [ptzControl])
  const turnRight = useCallback(() => {
    ptzControl(3)
  }, [ptzControl])
  const turnUp = useCallback(() => {
    ptzControl(0)
  }, [ptzControl])
  const turnDown = useCallback(() => {
    ptzControl(1)
  }, [ptzControl])
  const turnleftup = useCallback(() => {
    ptzControl(4)
  }, [ptzControl])
  const turnleftdown = useCallback(() => {
    ptzControl(5)
  }, [ptzControl])
  const turnrightup = useCallback(() => {
    ptzControl(6)
  }, [ptzControl])
  const turnrightdown = useCallback(() => {
    ptzControl(7)
  }, [ptzControl])
  const nearFocus = useCallback(() => {
    ptzControl(8)
  }, [ptzControl])
  const farFocus = useCallback(() => {
    ptzControl(9)
  }, [ptzControl])
  const stopControl = useCallback(() => {
    const url = `https://open.ys7.com/api/lapp/device/ptz/stop?accessToken=${Token}&deviceSerial=K06025120&channelNo=1`
    axios.post(url)
    // axios({
    //   method: 'POST',
    //   url: 'https://open.ys7.com/api/lapp/device/ptz/stop?accessToken=at.4wskp2u98er2ywhb8n40w0q3bhsm3h70-1e814cbg5i-1m1tz97-hphkq1utj&deviceSerial=K06025120&channelNo=1'
    // })
  }, [Token])
  // const onOpen = useCallback(() => {
  //   alert('开启摄像机')
  //   axios({
  //     method: 'get',
  //     url: 'https://www.duomiai.cn/api/v1/camera/devices/control?v=1'
  //   })
  // }, [])
  // const onClose = useCallback(() => {
  //   alert('关闭摄像机')
  //   axios({
  //     method: 'get',
  //     url: 'https://www.duomiai.cn/api/v1/camera/devices/control?v=0'
  //   })
  // }, [])
  const a_src = 'https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/K06025120/1.hd.live&accessToken=' + Token + '&id=ysopen'
  // 电路板电压
  const [voltage, setvoltage] = useState<number>(0)
  async function getData () {
    const data = await getVoltage()
    if (data.stat === 'OK') {
      setvoltage(data.MD1[0].voltage)
    } else {
      message.error('获取电压数据失败！')
    }
  }

  useEffect(() => {
    (async function handleData () {
      await getData()
      // await getTokenData()
    })()
  }, [])

  return (
    <div className={style.mon_wrap2}>
      <BorderBox10 className={style.borderbox10}>
        <div className={style.border_content}>
          <div className={style.title_wrap}>
            <i className='icont-font icon-pingtaijiyewujiankong module'></i>
            <h2 className={style.title}>md1监控</h2>
          </div>
          <div className={style.content2_wrap}>
            <div className={style.video2_wrap}>
            <iframe
            src={a_src}
            // src='https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/K06025120/1.hd.live&accessToken=at.4wskp2u98er2ywhb8n40w0q3bhsm3h70-1e814cbg5i-1m1tz97-hphkq1utj&id=ysopen'
            // src='https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/K06025120/&autoplay=1&accessToken=at.a48ieaht7n4ne4tr9uipd86j35vwz4y4-9rijfi2zi3-12gr7fm-dz3piqidg'
            width="100%"
            height="88%"
            id="ysOpenDevice"
            allowFullScreen
            >
            </iframe>
            </div>
            {/* <Switch checkedChildren={'开启'} unCheckedChildren={'关闭'} defaultChecked={!!value} disabled /> */}
            <div className={style.buttons2_box}>
              <div className={style.collapse2_wrap}>
              <button onClick={history.goBack} className={style.icon_all}>
                  退出全屏
              </button>
              /n
              <button className={style.icon_all}>
                  云台控制
              </button>
              <div className={style.row_wrap}>
              <button onMouseDown={turnleftup} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_zuoshang'></i>
                </span>
              </button>
              <button onMouseDown={turnUp} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_xiangshang'></i>
                </span>
              </button>
              <button onMouseDown={turnrightup} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_youshang'></i>
                </span>
              </button>
              </div>
              <div className={style.row_wrap}>
              <button onMouseDown={turnLeft} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_xiangzuo'></i>
                </span>
              </button>
              <button className={style.icon_all}>
                <span className='icon_circle2plus'>
                  {/* <i className='icont-font icon-jiantou_xiangyou'></i> */}
                </span>
              </button>
              <button onMouseDown={turnRight} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_xiangyou'></i>
                </span>
              </button>
              </div>
              <div className={style.row_wrap}>
              <button onMouseDown={turnleftdown} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_zuoxia'></i>
                </span>
              </button>
              <button onMouseDown={turnDown} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_xiangxia'></i>
                </span>
              </button>
              <button onMouseDown={turnrightdown} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiantou_youxia'></i>
                </span>
              </button>
              </div>
              /N
              <button className={style.icon_all}>
                   聚焦控制
              </button>
              <div className={style.row_wrap2}>
              <button onMouseDown={nearFocus} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jiahao'></i>
                </span>
              </button>
              <button onMouseDown={farFocus} onMouseUp={stopControl} className={style.icon_all}>
                <span className='icon_circle2'>
                  <i className='icont-font icon-jianhao'></i>
                </span>
              </button>
              </div>
              /N
              {/* <div className={style.row_wrap2}>
              <button onClick={onOpen} className={style.icon_all}>
                    开
              </button>
              <button onClick={onClose} className={style.icon_all}>
                    关
              </button>
              </div> */}
              /n
              <div className={style.icon_voltage} >
                    电压大小: {voltage}V
              </div>
              /n
              <button className={style.icon_all}>
                  设备状态
              </button>
              <div className={style.icon_voltage} >
              <Switchbutton/>
              </div>
              </div>
            </div>
          </div>
        </div>
      </BorderBox10>
    </div>
  )
}
