import { BorderBox10 } from '@jiaminghi/data-view-react'
import { useCallback, useEffect, useState } from 'react'
import '@/assets/styles/icon.scss'
import style from './style.module.scss'
import axios from 'axios'
// import Switchbutton from '../SwitchButton'
import { NavLink } from 'react-router-dom'
import { getToken } from '@/services/camera'
// import { message } from 'antd'

export default function Monitor() {
  const [Token, setToken] = useState<string>('')
  useEffect(() => {
    async function getTokenData() {
      const data = await getToken()
      // if (data.data.code === '200') {
      setToken(data.data.accessToken)
      // } else {
      // message.error('获取accessToken失败！')
      // }
    }
    getTokenData()
  }, [])
  const b_src = 'https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/J99041804/1.live&autoplay=1&accessToken=' + Token
  const onOpen = useCallback(() => {
    alert('开启摄像机')
    axios({
      method: 'get',
      url: 'https://www.duomiai.cn/api/v1/camera/devices/control?v=1'
    })
  }, [])
  const onClose = useCallback(() => {
    alert('关闭摄像机')
    axios({
      method: 'get',
      url: 'https://www.duomiai.cn/api/v1/camera/devices/control?v=0'
    })
  }, [])
  return (
    <div className={style.mon_wrap}>
      <BorderBox10 className={style.borderbox10}>
        <div className={style.border_content}>
          <div className={style.title_wrap}>
            <i className='icont-font icon-pingtaijiyewujiankong module'></i>
            <h2 className={style.title}>nb1监控</h2>
          </div>
          <iframe
            // src='https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/J99041804//1.live&accessToken=at.4wskp2u98er2ywhb8n40w0q3bhsm3h70-1e814cbg5i-1m1tz97-hphkq1utj&id=ysopen'
            src={b_src}
            width="100%"
            height="88%"
            id="ysOpenDevice"
            allowFullScreen
            >
            </iframe>
            <div className={style.buttons_box}>
            {/* <div className={style.collapse_wrap}> */}
              <NavLink className={style.icons} to="/nb_1max">
                <span className='icon_nocircle'>
                  <i className='icont-font icon-quanping'></i>
                </span>
              </NavLink>
              <button onClick={onOpen} className={style.icons}>
                {/* <span className='icon_circle2'> */}
                  开
              </button>
              <button onClick={onClose} className={style.icons}>
                  关
              </button>
              </div>
              {/* </div> */}
            </div>
      </BorderBox10>
    </div>
  )
}
