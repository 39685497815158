import axios from 'axios'

import { IMosquito, IMosquitopic, IMosquitopicData } from '@/model/mosquito'

// 路由前缀
// const PREFIX = '/auto/api/select_mosquitoAndequipment'

/**
 * @: 获取蚊虫诱杀数据
 * @param {*}
 * @return {*}
 */
// export async function getMosquito () {
//   const data = await axios.get<IMosquito>(PREFIX)
//   return data.data
// }

export async function getMosquito () {
  const data = await axios.get<IMosquito>('https://www.duomiai.cn/api/getRealTimeData')
  return data.data
}
// 最新的
export async function getMosquitopic () {
  const data = await axios.get<IMosquitopic>('https://www.duomiai.cn/api/getPictureUrlList')
  return data.data
}
// 识别出来的
export async function getMosquitopicData () {
  const data = await axios.get<IMosquitopicData>('https://www.duomiai.cn/api/getMarkPictureUrl')
  return data.data
}
